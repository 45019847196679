import {Popup} from "./Popup";
import {Toast} from "toaster-js";

export class BaseFileUploadPopup extends Popup {
    constructor(selector, onSubmit = () => {}) {
        super(selector, onSubmit);
        this.initElements();
        this.initEvents();
    }

    initElements() {
        Object.assign(this.elements, {
            fileUploader: this.popup.querySelector('#file-input'),
            selectedFiles: this.popup.querySelector('#file-list'),
            uploadButton: this.popup.querySelector('.popup__submit'),
            uploadArea: this.popup.querySelector('.popup__upload-area'),
        });
    }

    initEvents() {
        this.elements.fileUploader.addEventListener('change', this.handleFileUploaderChange.bind(this));
        this.elements.selectedFiles.addEventListener('click', this.handleSelectedFilesClick.bind(this));
        this.initDragAndDropEvents();

    }

    initDragAndDropEvents() {
        this.elements.uploadArea.addEventListener('dragover', (e) => {
            e.preventDefault();
            this.elements.uploadArea.style.borderColor = '#FF7F00';
        });

        this.elements.uploadArea.addEventListener('dragleave', () => {
            this.elements.uploadArea.style.borderColor = '#D0D5DD';
        });

        this.elements.uploadArea.addEventListener('drop', (e) => {
            e.preventDefault();
            this.elements.uploadArea.style.borderColor = '#D0D5DD';
            this.handleFileUploaderChange({target: {files: e.dataTransfer.files}});
        });
    }

    handleSelectedFilesClick(e) {
        if (e.target.classList.contains('popup__file-remove')) {
            this.handleFileRemove(e);
        }
    }

    getFileItemHTML(file) {
        return `
            <svg class="popup__file-icon" width="36" height="48" viewBox="0 0 36 48">
               <use href="#file" />
            </svg>
            <div class="popup__file-info">
                  <span class="popup__file-name">${file.name}</span>
                  <div class="popup__file-progress" id="progress-${file.lastModified}"></div>
                  <span class="popup__file-size" id="size-${file.lastModified}">0 of ${(file.size / 1024 / 1024).toFixed(2)} MB</span>
            </div>
            <button class="popup__file-remove" type="button" data-id="${file.lastModified}">
                <svg class="popup__file-remove-icon" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <use href="#close" />
                </svg>
            </button>
        `;
    }

    validateFile(file) {
        const listOfForbiddenExtensions = [
            'exe', 'bat', 'cmd', 'sh', 'msi', 'com',
            'vbs', 'js', 'jar', 'py', 'php', 'html',
            'htm', 'css', 'scss', 'sass', 'less',
        ];
        if (listOfForbiddenExtensions.includes(file.name.split('.').pop())) {
            return false;
        }
        return true;
    }

    handleFileUploaderChange(e) {
        const file = e.target.files[0];
        if (!file) return;
        if (!this.validateFile(file)) {
            new Toast("This file type is not allowed", Toast.TYPE_ERROR, Toast.TIME_NORMAL);
            return;
        }

        this.state.file = file;
        this.elements.selectedFiles.innerHTML = '';

        const reader = new FileReader();
        const fileItem = document.createElement('div');
        fileItem.classList.add('popup__file');
        fileItem.dataset.id = file.lastModified;
        fileItem.innerHTML = this.getFileItemHTML(file);

        this.elements.selectedFiles.appendChild(fileItem);
        this.initFileProgress(file, reader);

        reader.readAsDataURL(file);
    }

    initFileProgress(file, reader) {
        const progress = document.getElementById(`progress-${file.lastModified}`);
        const progressLabel = document.getElementById(`size-${file.lastModified}`);

        reader.onprogress = (e) => {
            if (e.lengthComputable) {
                progress.style.setProperty('--value', `${(e.loaded / e.total) * 100}%`);
                progressLabel.textContent = `${(e.loaded / 1024 / 1024).toFixed(2)} MB of ${(e.total / 1024 / 1024).toFixed(2)} MB`;
            }
        };

        reader.onload = () => {
            progress.style.setProperty('--value', '100%');
        };
    }
}
