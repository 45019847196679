import ApiService from "./ApiService";

class TaskService extends ApiService {
    static async create(groupId, title, description) {
        const formData = new FormData();
        formData.append('group_id', groupId);
        formData.append('name', title);
        formData.append('description', description);
        return await this._fetchWithErrorHandling(`/boards/tasks/create-task`, {
            method: 'POST',
            body: formData,
        });
    };

    static async update(id, payload) {
        return await this._fetchWithErrorHandling(`/boards/tasks/${id}`, {
            method: 'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json'
            },
        });
    }

    static async delete(id) {
        return await this._fetchWithErrorHandling(`/boards/tasks/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json'
            }
        });
    }
}

export default TaskService;
