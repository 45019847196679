const sidebarOpenBtns = document.querySelectorAll('.header__menu-btn, .sidebar__close-btn');
const headerBtn = document.querySelector('.header__menu-btn');
const sidebar = document.querySelector('.sidebar');


sidebarOpenBtns.forEach((btn) => {
    btn.addEventListener('click', () => {
        sidebar.classList.toggle('active');
        headerBtn.classList.toggle('active');
    })
});
