class ApiService {
    static _token = document.querySelector('meta[name="csrf-token"]').content;

    static async _fetchWithErrorHandling(url, options = {}) {
        if (!options.headers) {
            options.headers = {'X-CSRF-TOKEN': this._token};
        } else if (!options.headers['X-CSRF-TOKEN']) {
            options.headers['X-CSRF-TOKEN'] = this._token;
        }
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error((await response.json())?.message ?? `API call failed: ${response.statusText}`);
        }
        return await response.json();
    }
}

export default ApiService;
