class CustomSelect {
    constructor(className, cssSelector) {
        if (cssSelector) {
            this.elements = document.querySelectorAll(cssSelector);
        } else {
            this.elements = document.getElementsByClassName(className);
        }
        this.init();
    }

    init() {
        for (let i = 0; i < this.elements.length; i++) {
            const select = this.elements[i].getElementsByTagName("select")[0];
            this.createCustomSelect(this.elements[i], select);
        }
        document.addEventListener("click", this.closeAllSelect.bind(this));
    }

    createCustomSelect(wrapper, select) {
        const selectedDiv = document.createElement("DIV");
        selectedDiv.setAttribute("class", "select-selected");
        selectedDiv.innerHTML = select.options[select.selectedIndex].innerHTML;
        wrapper.appendChild(selectedDiv);

        const optionsDiv = document.createElement("DIV");
        optionsDiv.setAttribute("class", "select-items select-hide");

        for (let j = 0; j < select.length; j++) {
            const optionDiv = document.createElement("DIV");
            optionDiv.innerHTML = select.options[j].innerHTML;
            optionDiv.classList.add(select.options[j].getAttribute('class'));
            optionDiv.addEventListener("click", (e) => {
                e.stopPropagation();
                this.handleOptionClick(e, select, selectedDiv, wrapper)
            });
            optionsDiv.appendChild(optionDiv);
        }

        wrapper.appendChild(optionsDiv);
        selectedDiv.addEventListener("click", (e) => this.handleSelectClick(e, selectedDiv));
    }

    handleOptionClick(e, select, selectedDiv, wrapper) {
        const clickedOption = e.target;
        for (let i = 0; i < select.length; i++) {
            if (select.options[i].innerHTML === clickedOption.innerHTML) {
                select.selectedIndex = i;
                selectedDiv.innerHTML = clickedOption.innerHTML;
                const sameAsSelected = clickedOption.parentNode.getElementsByClassName("same-as-selected");
                for (let k = 0; k < sameAsSelected.length; k++) {
                    sameAsSelected[k].classList.remove("same-as-selected");
                }
                clickedOption.classList.add('same-as-selected');
                wrapper.setAttribute("class", clickedOption.className.replace('same-as-selected', '') + " custom-select");
                break;
            }
        }

        const event = new Event('change', { 'bubbles': true });
        select.dispatchEvent(event);
        selectedDiv.click();
    }

    handleSelectClick(e, selectedDiv) {
        e.stopPropagation();
        this.closeAllSelect(selectedDiv);
        selectedDiv.nextSibling.classList.toggle("select-hide");
        selectedDiv.classList.toggle("select-arrow-active");
    }

    closeAllSelect(elmnt) {
        const selectItems = document.getElementsByClassName("select-items");
        const selectSelected = document.getElementsByClassName("select-selected");
        const arrNo = [];

        for (let i = 0; i < selectSelected.length; i++) {
            if (elmnt === selectSelected[i]) {
                arrNo.push(i);
            } else {
                selectSelected[i].classList.remove("select-arrow-active");
            }
        }

        for (let i = 0; i < selectItems.length; i++) {
            if (arrNo.indexOf(i)) {
                selectItems[i].classList.add("select-hide");
            }
        }
    }
}
export { CustomSelect };

const customSelect = new CustomSelect("custom-select");

