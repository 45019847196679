const sidebar = document.querySelector('.sidebar');
const headerBtn = document.querySelector('.header__menu-btn');

document.addEventListener('click', function(e) {
    if (e.target.classList.contains('sidebar__menu-link') || e.target.closest('.sidebar__menu-link')) {
        document.querySelectorAll('.sidebar__menu-item').forEach(item => {
            item.classList.remove('active');
        });
        e.target.closest('.sidebar__menu-item').classList.add('active');
        sidebar.classList.remove('active');
        headerBtn.classList.remove('active');
    }
});

const anchor = window.location.hash;

if (anchor) {
    const targetLink = document.querySelector(`[href="${anchor}"]`);
    if (targetLink) {
        targetLink.closest('.sidebar__menu-item').classList.add('active');
    }
}
