import Swal from "sweetalert2";

export const DeleteAlert = Swal.mixin({
    customClass: {
        confirmButton: 'delete-alert__button delete-alert__confirm button',
        cancelButton: 'delete-alert__button delete-alert__cancel button button--inverted',
        icon: 'delete-alert__icon',
        title: 'delete-alert__header',
        popup: 'delete-alert',
        actions: 'delete-alert__actions',
    },
    width: '600px',
    buttonsStyling: false,
    iconHtml: '<svg class="delete-alert__icon-svg" width="120" height="120" viewBox="0 0 120 120" ><use href="#alert" /></svg>',
    showCancelButton: true,
    confirmButtonText: "Delete",
});
