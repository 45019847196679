import {BaseFileUploadPopup} from "./BaseFileUploadPopup";
import {Toast} from "toaster-js";

export class MultipleFileUploadPopup extends BaseFileUploadPopup {
    constructor(selector, onSubmit = () => {}) {
        super(selector, onSubmit);
        this.state = {
            files: [],
        };
    }

    handleFileUploaderChange(e) {
        const newFiles = Array.from(e.target.files);
        if (newFiles.length === 0) return;

        newFiles.forEach(file => this.addFileToList(file));
    }

    addFileToList(file) {
        if (!this.validateFile(file)) {
            new Toast("Invalid file type", Toast.TYPE_ERROR, Toast.TIME_NORMAL);
            return;
        }
        if (this.state.files.some(f => f.lastModified === file.lastModified && f.size === file.size && f.name === file.name)) {
            new Toast("File already exists", Toast.TYPE_ERROR, Toast.TIME_NORMAL);
            return;
        }
        this.state.files.push(file);
        const reader = new FileReader();
        const fileItem = document.createElement('div');
        fileItem.classList.add('popup__file');
        fileItem.dataset.id = file.lastModified;
        fileItem.innerHTML = this.getFileItemHTML(file);

        this.elements.selectedFiles.appendChild(fileItem);
        this.initFileProgress(file, reader);

        reader.readAsDataURL(file);
    }

    handleFileRemove(e) {
        const fileId = e.target.dataset.id;
        this.state.files = this.state.files.filter(file => file.lastModified.toString() !== fileId);
        const fileItem = this.elements.selectedFiles.querySelector(`[data-id="${fileId}"]`);
        if (fileItem) {
            fileItem.remove();
        }
    }
}
