import ApiService from "./ApiService";

class GroupService extends ApiService {
    static async create (userId, title){
        const formData = new FormData();
        formData.append('name', title);
        return await this._fetchWithErrorHandling(`/boards/${userId}/groups`, {
            method: 'POST',
            body: formData,
        });
    }

    static async update(id, title) {
        const formData = new FormData();
        formData.append('name', title);
        formData.append('_method', 'PUT');
        return await this._fetchWithErrorHandling(`/boards/groups/${id}`, {
            method: 'POST',
            body: formData,
        });
    }

    static async delete(id) {
        return await this._fetchWithErrorHandling(`/boards/groups/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
            }
        });
    }
}

export default GroupService;
