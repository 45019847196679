export class Popup {
    constructor(selector, onSubmit = () => {}) {
        this.popup = document.querySelector(selector);
        this.elements = {
            closeBtn: this.popup.querySelector('.popup__close'),
            heading: this.popup.querySelector('.popup__heading'),
            content: this.popup.querySelector('#popup-content'),
        };
        this.onSubmit = onSubmit;
        this.elements.closeBtn.addEventListener('click', this.close.bind(this));
        this.popup.addEventListener('mousedown', (e) => {
            if (e.target === this.popup) {
                this.close();
            }
        });
        this.setContent();
        this.elements.form = this.popup.querySelector('.popup__form');
        this.elements.form.addEventListener('submit', this.handleSubmit.bind(this));
    }

    setContent() {
        throw new Error('Method setContent must be implemented');
    }

    setHeading(heading) {
        this.elements.heading.textContent = heading;
    }

    open() {
        this.popup.classList.add('active');
    }

    close() {
        this.popup.classList.remove('active');
    }

    handleSubmit(e) {
        e.preventDefault();
        // To be implemented in child classes
    }
}
