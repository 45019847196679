import {urlToAnchor} from "../urlToAnchor";
import {Toast} from "toaster-js";
import {MultipleFileUploadPopup} from "../components/popup";
import CommentService from "../services/CommentService";

class ChatPopup extends MultipleFileUploadPopup {
    constructor(selector, onSubmit = () => {}) {
        super(selector, onSubmit);
        this.state.previousMessageUser = null;
        this.state.messageGroup = null;
    }

    setContent() {
        this.elements.content.innerHTML = `
            <form class="popup__form">
                <textarea class="popup__textarea" required name="message" rows="4" placeholder="Write an update..."></textarea>
                <div class="popup__upload-area" id="upload-area">
                    <svg width="24" height="24" viewBox="0 0 25 24" class="popup__upload-icon">
                        <use href="#upload" />
                    </svg>
                    <p class="popup__upload-title">Upload files</p>
                    <p class="popup__upload-text">Drop files directly here or <label for="file-input" class="popup__upload-label">browse</label> from your device</p>
                    <input type="file" id="file-input" multiple style="display: none;">
                </div>
                <div id="file-list" class="popup__file-list">

                </div>
                <div class="popup__chat"></div>
                <button type="submit" class="popup__submit button">Update</button>
            </form>
        `;

    }

    initElements() {
        super.initElements();
        Object.assign(this.elements, {
            messageArea: this.popup.querySelector('.popup__textarea'),
            chat: this.popup.querySelector('.popup__chat'),
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.onSubmit(this.elements.messageArea.value, this.state.files, this);
        this.elements.messageArea.value = '';
        this.state.files = [];
        this.elements.selectedFiles.innerHTML = '';
    }

    addMessage(message, userName, isUser = false) {
        if (!this.state.messageGroup || this.state.previousMessageUser !== userName) {
            this.state.messageGroup = document.createElement('div');
            this.state.messageGroup.classList.add('popup__message-group', isUser ? 'popup__message-group--user' : 'popup__message-group--other');
            const user = document.createElement('div');
            user.classList.add('popup__message-user');
            user.textContent = userName;
            this.state.messageGroup.appendChild(user);
            this.state.previousMessageUser = userName;
            this.elements.chat.appendChild(this.state.messageGroup);
        }

        const messageItem = document.createElement('div');
        messageItem.classList.add('popup__message', isUser ? 'popup__message--user' : 'popup__message--other');
        messageItem.innerHTML = message;
        this.state.messageGroup.appendChild(messageItem);

        this.updateMessageGroupClass();
    }

    updateMessageGroupClass() {
        const messageCount = this.state.messageGroup.querySelectorAll('.popup__message').length;
        this.state.messageGroup.classList.remove('popup__message-group--one', 'popup__message-group--two', 'popup__message-group--many');
        if (messageCount === 1) {
            this.state.messageGroup.classList.add('popup__message-group--one');
        } else if (messageCount === 2) {
            this.state.messageGroup.classList.add('popup__message-group--two');
        } else {
            this.state.messageGroup.classList.add('popup__message-group--many');
        }
    }
}

const handleCreateComment = async (taskId, message, file, popup) => {
    try {
        const data = await CommentService.create(taskId, message, file);
        if (message) {
            popup.addMessage(urlToAnchor(message), data.user.name, true);
        }
        if (data.comment.file_path) {
            popup.addMessage(`File: <a href="/${data.comment.file_path}" target="_blank">${data.comment.file_name}</a>`, data.user.name, true);
        }
        const sidebarItem = document.querySelector(`.sidebar__menu a[href="#group-${data.group_id}"]`);
        const sidebarCount = sidebarItem?.querySelector('.sidebar__menu-item-count');
        if (sidebarCount) {
            if (data.unseen) {
                sidebarCount.textContent = data.unseen;
            } else {
                sidebarCount.remove();
            }
        }
    } catch (error) {
        console.error(error);
        new Toast("Something went wrong! Please try again.", Toast.TYPE_ERROR, Toast.TIME_NORMAL);
    }
};


document.addEventListener('click', async (e) => {
    if (e.target.classList.contains('task__comment-btn') || e.target.closest('.task__comment-btn')) {
        e.stopImmediatePropagation();
        const taskId = e.target.closest('.task').dataset.id;
        const chatPopup = new ChatPopup('.popup', async (message, files, popup) => {
            await handleCreateComment(taskId, message, files[0], popup);
            files.slice(1).forEach(file => {
                handleCreateComment(taskId, "", file, popup);
            });
        });
        const heading = e.target.closest('.task').querySelector('.task__name')?.textContent || "Comments";
        chatPopup.setHeading(heading);

        try {
            const {comments, user_id} = await CommentService.get(taskId);
            comments.forEach(comment => {
                if (comment.comment) {
                    chatPopup.addMessage(urlToAnchor(comment.comment), comment.user.name, comment.user.id === user_id);
                }
                if (comment.file_path) {
                    chatPopup.addMessage(`File: <a href="/${comment.file_path}" target="_blank">${comment.file_name}</a>`, comment.user.name, comment.user.id === user_id);
                }
            });
            chatPopup.open();
        } catch (error) {
            console.error(error);
            new Toast("Failed to load comments. Please try again.", Toast.TYPE_ERROR, Toast.TIME_NORMAL);
        }
    }
})
