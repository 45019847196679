import ApiService from "./ApiService";

class CommentService extends ApiService {
    static async get(taskId) {
        return await this._fetchWithErrorHandling(`/boards/tasks/${taskId}/comments`);
    }

    static async create(taskId, message, file) {
        const formData = new FormData();
        formData.append('message', message);
        if (file) {
            formData.append('file', file);
        }

        return  await this._fetchWithErrorHandling(`/boards/tasks/${taskId}/comments`, {
            method: 'POST',
            body: formData,
        });
    }
}

export default CommentService;
