import { Toast } from "toaster-js";
import "toaster-js/default.scss";
import './components/select';
import { Calendar } from "./components/datepicker";
import {DeleteAlert} from "./components/deleteAlert";
import GroupService from "./services/GroupService";
import TaskService from "./services/TaskService";


class TaskManager {
    previousColsCount = 0;
    constructor() {
        this.initEventListeners();
        this.handleInitCalendar();
    }

    static getColsCount() {
        const displayWidth = window.innerWidth;
        if (displayWidth < 1700 && displayWidth > 1250) return 3;
        if (displayWidth <= 1250 && displayWidth > 768) return 2;
        if (displayWidth <= 768) return 1;
        return 4;
    }

    static setExpandableTaskGroup() {
        const cols = this.getColsCount();
        if (cols === this.previousColsCount) return;
        this.previousColsCount = cols;
        const taskGroups = document.querySelectorAll('.task__group');

        taskGroups.forEach((taskGroup) => {
            const taskWrapper = taskGroup.querySelector('.task__wrapper');
            const expandBtn = taskGroup.querySelector('.task__see-all');
            const taskCount = taskWrapper.querySelectorAll('.task').length;

            if (taskWrapper.dataset.oppenable === 'false') return;

            const taskItemHeight = taskWrapper.querySelector('.task')?.offsetHeight;

            if (taskItemHeight) {
                taskWrapper.style.maxHeight = `${taskItemHeight}px`;
            }
            taskWrapper.classList.toggle('task__wrapper--open', cols === taskCount);
            expandBtn.style.display = taskWrapper.scrollHeight === taskWrapper.offsetHeight ? 'none' : 'block';
        });
    }

    handleInitCalendar() {
        document.querySelectorAll('.calendar__wrapper').forEach((el) => {
            TaskManager.initCalendar(el);
        });
    }

    static initCalendar(el) {
        new Calendar(el, TaskManager.handleTaskUpdate);
    }

    toggleTaskWrapper(taskWrapper, expandBtn) {
        const isOpen = taskWrapper.classList.toggle('task__wrapper--open');
        expandBtn.textContent = isOpen ? 'See less' : 'See all';
        const taskItemHeight = taskWrapper.querySelector('.task').offsetHeight;

        if (isOpen) {
            taskWrapper.style.maxHeight = `${taskWrapper.scrollHeight}px`;
        } else {
            taskWrapper.style.maxHeight = `${taskItemHeight}px`;
        }
    }

    static async handleTaskUpdate(id, payload) {
        try {
            const {unseen, task} = await TaskService.update(id, payload);
            const sidebarItem = document.querySelector(`.sidebar__menu a[href="#group-${task.group_id}"]`);
            const sidebarCount = sidebarItem.querySelector('.sidebar__menu-item-count');
            if (sidebarCount) {
                if (unseen) {
                    sidebarCount.textContent = unseen;
                } else {
                    sidebarCount.remove();
                }
            }
            new Toast("Task updated successfully.", Toast.TYPE_DONE, Toast.TIME_NORMAL);
        } catch (error) {
            new Toast("Something went wrong! Please try again.", Toast.TYPE_ERROR, Toast.TIME_NORMAL);
        }
    }

    async deleteGroup(id) {
        try {
            await GroupService.delete(id);
            new Toast("Group deleted successfully.", Toast.TYPE_DONE, Toast.TIME_NORMAL);
            document.querySelector(`#group-${id}`).remove();
        } catch (error) {
            new Toast("Something went wrong! Please try again.", Toast.TYPE_ERROR, Toast.TIME_NORMAL);
        }
    }

    async deleteTask(id) {
        try {
            await TaskService.delete(id);
            new Toast("Task deleted successfully.", Toast.TYPE_DONE, Toast.TIME_NORMAL);
            document.querySelector(`.task[data-id="${id}"]`).remove();
            TaskManager.setExpandableTaskGroup();
        } catch (error) {
            new Toast("Something went wrong! Please try again.", Toast.TYPE_ERROR, Toast.TIME_NORMAL);
        }
    }

    async handleGroupDelete(e) {
        const result = await DeleteAlert.fire({
            title: "Delete this group?",
        })

        const id = e.target.dataset.group;

        if (result.isConfirmed) {
            await this.deleteGroup(id);
        }
    }

    async handleTaskDelete(e) {
        const result = await DeleteAlert.fire({
            title: "Delete this task?",
        })

        const id = e.target.dataset.id;

        if (result.isConfirmed) {
            await this.deleteTask(id);
        }
    }

    static handleDescriptionResize() {
        const description = document.querySelectorAll('.task__description');
        const name = document.querySelectorAll('.task__name');

        description.forEach((el, i) => {
            const nameHeight = name[i].offsetHeight;
            if (nameHeight <= 40) el.classList.remove('task__description--small');
            else el.classList.add('task__description--small');
        });
    }

    initEventListeners() {
        document.addEventListener('userChanged', () => {
            TaskManager.setExpandableTaskGroup();
            TaskManager.handleDescriptionResize();
            this.handleInitCalendar();
        });

        window.addEventListener('resize', () => {
            TaskManager.handleDescriptionResize();
            TaskManager.setExpandableTaskGroup()
        });

        document.addEventListener('click', async (e) => {
            if (e.target.classList.contains('task__see-all')) {
                e.preventDefault();
                const taskGroup = e.target.closest('.task__group');
                const taskWrapper = taskGroup.querySelector('.task__wrapper');
                return this.toggleTaskWrapper(taskWrapper, e.target);
            }
            if (e.target.classList.contains('task__group-delete')) {
                return await this.handleGroupDelete(e);
            }

            if (e.target.classList.contains('task__delete-btn')) {
                e.stopImmediatePropagation()
                return await this.handleTaskDelete(e);
            }
        });

        document.addEventListener('change', (e) => {
            if (e.target.classList.contains('task__status-select')) {
                const taskId = e.target.closest('.task').dataset.id;
                TaskManager.handleTaskUpdate(taskId, { status: e.target.value });
            }}
        );
    }
}

// Initialize the TaskManager
export { TaskManager };


