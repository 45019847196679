export function urlToAnchor(text) {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Function to sanitize URL
    function sanitizeUrl(url) {
        try {
            const parsed = new URL(url);
            // Only allow http and https protocols
            if (parsed.protocol !== 'http:' && parsed.protocol !== 'https:') {
                return '#';
            }
            return parsed.href;
        } catch (e) {
            // If URL is invalid, return a harmless link
            return '#';
        }
    }

    // Function to escape HTML special characters
    function escapeHtml(unsafe) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    // Replace URLs with anchor tags
    return text.replace(urlRegex, function(url) {
        const sanitizedUrl = sanitizeUrl(url);
        const escapedUrl = escapeHtml(url);
        return `<a href="${sanitizedUrl}" target="_blank" rel="noopener noreferrer">${escapedUrl}</a>`;
    });
}
