export class Calendar {
    static dateCache = new Map();
    static rowCountCache = new Map();
    static options = { year: 'numeric', month: 'long', day: 'numeric' };
    static headerOptions = { year: 'numeric', month: 'long' };
    static sharedDOMPool = new Map();

    constructor(container, updateCallback = () => {}) {
        this.container = container;
        this.updateCallback = updateCallback;
        this.state = {
            current: new Date(),
            isInitialized: false
        };
        this.elements = {};

        this.container.addEventListener("mouseenter", () => this.lazyInit());
    }

    lazyInit() {
        if (!this.state.isInitialized) {
            this.initElements();
            this.attachEventListeners();
            this.render();
            this.state.isInitialized = true;
        }
    }

    initElements() {
        this.elements = {
            datepickerInput: this.container.querySelector("#datepickerInput"),
            calendar: this.container.querySelector("#calendar"),
            calendarHeader: this.container.querySelector("#calendarHeader"),
            days: this.container.querySelector("#days"),
            saveButton: this.container.querySelector("#dateSave"),
            clearButton: this.container.querySelector("#dateClear")
        };
    }

    render() {
        requestAnimationFrame(() => {
            this.renderHeader();
            this.renderDates();
        });
    }

    renderHeader() {
        this.elements.calendarHeader.textContent = Calendar.formatDate(this.state.current, Calendar.headerOptions);
    }

    renderDates() {
        const fragment = document.createDocumentFragment();
        const date = new Date(this.state.current.getFullYear(), this.state.current.getMonth(), 1);
        date.setDate(date.getDate() - date.getDay());

        const rowCount = Calendar.getCalendarRowCount(this.state.current.getFullYear(), this.state.current.getMonth());

        for (let i = 0; i < rowCount * 7; i++) {
            fragment.appendChild(this.renderDate(date));
            date.setDate(date.getDate() + 1);
        }

        this.elements.days.innerHTML = "";
        this.elements.days.appendChild(fragment);
    }

    static getCalendarRowCount(year, month) {
        const key = `${year}-${month}`;
        if (!Calendar.rowCountCache.has(key)) {
            const firstDay = new Date(year, month, 1);
            const startDay = firstDay.getDay();
            const daysInMonth = new Date(year, month + 1, 0).getDate();
            const totalDays = daysInMonth + startDay;
            Calendar.rowCountCache.set(key, Math.ceil(totalDays / 7));
        }
        return Calendar.rowCountCache.get(key);
    }

    renderDate(date) {
        const key = date.toISOString();
        let cell;

        if (!Calendar.sharedDOMPool.has('dateCell')) {
            const templateCell = document.createElement("button");
            templateCell.className = "date";
            Calendar.sharedDOMPool.set('dateCell', templateCell);
        }

        cell = Calendar.sharedDOMPool.get('dateCell').cloneNode(true);
        cell.setAttribute("data-value", Calendar.formatDate(date, Calendar.options));
        cell.textContent = date.getDate();

        cell.classList.toggle("fadeout", date.getMonth() !== this.state.current.getMonth());
        cell.classList.toggle("today", Calendar.isToday(date));
        cell.classList.toggle("active", this.isSelected(date));

        return cell;
    }

    attachEventListeners() {
        this.elements.datepickerInput.addEventListener("click", (e) => {
            e.stopPropagation();
            this.toggleCalendar()
        });
        this.elements.calendar.addEventListener("click", (e) => e.stopPropagation());
        this.container.querySelector(".calendar__header-btn--prev").addEventListener("click", () => this.changeMonth(-1));
        this.container.querySelector(".calendar__header-btn--next").addEventListener("click", () => this.changeMonth(1));
        this.elements.days.addEventListener("click", (e) => {
            if (e.target.classList.contains("date")) {
                this.selectDate(e.target);
            }
        });
        this.elements.saveButton.addEventListener("click", () => this.saveCalendar());
        this.elements.clearButton.addEventListener("click", () => this.clearCalendar());

        document.addEventListener("click", (e) => {
            if (!this.elements.calendar.contains(e.target) && !this.elements.datepickerInput.contains(e.target)) {
                this.hideCalendar();
            }
        });
    }

    changeMonth(delta) {
        this.state.current.setMonth(this.state.current.getMonth() + delta);
        this.render();
    }

    selectDate(target) {
        this.elements.days.querySelectorAll('.date.active').forEach(el => el.classList.remove('active'));
        target.classList.add('active');
    }

    showCalendar() {
        this.elements.calendar.classList.remove("hidden");
        this.elements.calendar.closest('.task').classList.add('calendar-active');
    }

    clearCalendar() {
        this.elements.days.querySelectorAll('.date.active').forEach(el => el.classList.remove('active'));
        this.elements.datepickerInput.textContent = "Select Date";
        this.updateCallback(this.container.closest('.task').dataset.id, { date: this.elements.datepickerInput.textContent });
        this.hideCalendar();
    }

    saveCalendar() {
        const activeDate = this.elements.days.querySelector('.date.active');
        if (activeDate) {
            this.elements.datepickerInput.textContent = activeDate.dataset.value;
        }
        this.updateCallback(this.container.closest('.task').dataset.id, { date: this.elements.datepickerInput.textContent });
        this.hideCalendar();
    }

    hideCalendar() {
        this.elements.calendar.classList.add("hidden");
        this.elements.calendar.closest('.task').classList.remove('calendar-active');
    }

    toggleCalendar() {
        this.elements.calendar.classList.contains("hidden") ? this.showCalendar() : this.hideCalendar();
    }

    static formatDate(date, options) {
        return date.toLocaleDateString('en-US', options);
    }

    static isToday(date) {
        return Calendar.formatDate(date, Calendar.options) === Calendar.formatDate(new Date(), Calendar.options);
    }

    isSelected(date) {
        return this.elements.datepickerInput.textContent === Calendar.formatDate(date, Calendar.options);
    }
}
